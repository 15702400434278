import React from "react";
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero7
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="hero-section ptb-100 background-img"
             style={{
              background: `url(${this.state.hero.bgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "center / cover"
            }}>
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-sm-9 col-md-10 col-lg-7">
                    <div className="hero-content-left text-white text-center ptb-100">
                        <h1 className="text-white">{this.state.hero.title}</h1>
                        <p className="lead">{this.state.hero.description}</p>

                        <a href="/#" className="btn solid-btn">Get Start Now</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
      </React.Fragment>
    );
  }
}

export default HeroSection;
