module.exports = {
  promo: {
    title: "Why does your business need AgencyCo?",
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Keyword Rankings",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s using Social Proof & Urgency for your business.",
        image: "img/service6.jpg"
      },
      {
        title: "Backlink Analytics",
        description:
          "Build your online store’s trust using Social Proof & Urgency. Realize importance of social proof in customer’s purchase decision.",
        image: "img/service2.jpg"
      },
      {
        title: "Competitor Analysis",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics of your website.",
        image: "img/service7.jpg"
      }
    ]
  },
  promo2: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s the trust using Social Proof & Urgency for your business.",
        image: "img/promo-1.png",
        image8: "img/service6.jpg"
      },
      {
        title: "Increase store trust",
        description:
          "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
        image: "img/promo-2.png",
        image8: "img/service2.jpg"
      },
      {
        title: "Growth guaranteed",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        image: "img/promo-3.png",
        image8: "img/service7.jpg"
      }
    ]
  },
  promo3: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s the trust using Social Proof & Urgency for your business.",
        icon: "ti-bar-chart icon-color-1 icon rounded"
      },
      {
        title: "Increase store trust",
        description:
          "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
        icon: "ti-shield icon-color-2 icon rounded"
      },
      {
        title: "Growth guaranteed",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        icon: "ti-cup icon-color-3 icon rounded"
      }
    ]
  },
  promo6: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Proactively provide access to distinctive quality vectors for adaptive solutions.",
        image: "ti-bar-chart icon-md icon color-primary"
      },
      {
        title: "Increase store trust",
        description:
          "Interactively reintermediate reliable platforms rather than synergistic e-commerce.",
        image: "ti-headphone-alt icon-md icon color-primary"
      },
      {
        title: "Growth guaranteed",
        description:
          "Professionally pursue standards compliant networks whereas impactful experiences.",
        image: "ti-vector icon-md icon color-primary"
      }
    ]
  },
  promo7: {
    items: [
      {
        title: "Keyword Rankings",
        description: "Increase sales by showing true dynamics of your website.",
        image: "ti-bar-chart text-white"
      },
      {
        title: "Backlink Analytics",
        description:
          "Build your online store’s trust using Social Proof & Urgency.",
        image: "ti-vector text-white"
      },
      {
        title: "Competitor Analysis",
        description:
          "Realize importance of social proof in customer’s purchase decision.",
        image: "ti-stats-up text-white"
      }
    ]
  },
  aboutUs: {
    title: "Who we are",
    description:
      "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Increase conversion",
        description: "Display recent conversions, build credibility and trust.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Product analytics",
        description: "A top promo bar that counts down until a few discounts.",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3"
      }
    ],
    counts: [
      {
        title: "Active users",
        count: 1600,
        icon: "ti-medall-alt color-primary",
        boxClass: "single-card box-animation-1 secondary-bg text-white",
        boxClass5:
          "single-card box-animation-1 counter-card secondary-bg text-white"
      },
      {
        title: "Global client",
        count: 2500,
        icon: "ti-headphone-alt",
        boxClass: "single-card box-animation-2 icon-color-1",
        boxClass5: "single-card box-animation-2 counter-card icon-color-1"
      },
      {
        title: "Win award",
        count: 2150,
        icon: "ti-cup",
        boxClass: "single-card box-animation-3 icon-color-2",
        boxClass5: "single-card box-animation-3 counter-card icon-color-2"
      },
      {
        title: "Clients satisfaction",
        count: 2150,
        icon: "ti-user color-secondary",
        boxClass: "single-card box-animation-4 primary-bg text-white",
        boxClass5:
          "single-card box-animation-4 counter-card primary-bg text-white"
      }
    ]
  },
  aboutUs2: {
    title: "We help drive your business forward faster",
    description:
      "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology before team driven sources.",
    image: "img/hero-bg-5.jpg",
    items: [
      {
        title: "Increase conversion",
        description: "Display recent conversions, build credibility and trust.",
        longDescription:
          "Synergistically deliver next-generation relationships whereas bleeding-edge resources. Continually pontificate stand-alone benefits whereas.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Product analytics",
        description: "A top promo bar that counts down until a few discounts.",
        longDescription:
          "Phosfluorescently empower compelling intellectual capital and revolutionary web services. Compellingly develop cross-media.",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3"
      }
    ]
  },
  aboutUs6: {
    aboutImage: "img/business.png",
    items: [
      {
        title: "Discussion for Idea",
        description:
          "Enthusiastically coordinate competitive users vis-a-vis results.",
        longDescription: "",
        icon: "ti-light-bulb rounded mr-3 icon icon-color-3"
      },
      {
        title: "Perfect testing",
        description:
          "Credibly evisculate covalent quality vectors and maintainable.",
        longDescription: "",
        icon: "ti-paint-bucket rounded mr-3 icon icon-color-4"
      }
    ]
  },
  service: {
    title: "Happy to Assist You",
    description:
      "Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Synergistically deliver next-generation relationships whereas bleeding-edge resources.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-1"
      },
      {
        title: "Social media marketing",
        description:
          "Phosfluorescently empower compelling intellectual capital and revolutionary web services. Compellingly develop cross-media.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-2"
      }
    ]
  },
  service2: {
    title: "We are happy to assist you all time momentInsights",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        icon: "ti-bar-chart icon-color-1 icon rounded",
        icon6: "ti-bar-chart icon-md color-secondary",
        image: "img/service1.jpg"
      },
      {
        title: "Social media marketing",
        description:
          "While browsing the Facebook or Twitter or even Linkedin, you are subjected to advertisements for them",
        icon: "ti-layout-media-left-alt icon-color-2 icon rounded",
        icon6: "ti-layout-media-left-alt icon-md color-secondary",
        image: "img/service3.jpg"
      },
      {
        title: "Email marketing",
        description:
          "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies",
        icon: "ti-email icon-color-3 icon rounded",
        icon6: "ti-email icon-md color-secondary",
        image: "img/service2.jpg"
      },
      {
        title: "Web design and development",
        description:
          "Your website provides a place for interested users to learn more about your business, your products and services",
        icon: "ti-vector icon-color-4 icon rounded",
        icon6: "ti-vector icon-md color-secondary",
        image: "img/service7.jpg"
      },
      {
        title: "Mobile app development",
        description:
          "We are also working in mobile App Development that is skillful and professional in developing a Mobile application",
        icon: "ti-mobile icon-color-5 icon rounded",
        icon6: "ti-mobile icon-md color-secondary",
        image: "img/service5.jpg"
      },
      {
        title: "Amazon affiliate marketing",
        description:
          "Thrive was founded on the conviction that businesses can successfully outshine their competitors with a strong website",
        icon: "ti-announcement icon-color-6 icon rounded",
        icon6: "ti-announcement icon-md color-secondary",
        image: "img/service6.jpg"
      }
    ]
  },
  service4: {
    title: "We Are Happy to Assist You all Time MomentInsights",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Social media marketing",
        description:
          "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      }
    ]
  },
  service5: {
    title: "We Are Happy to Assist You all Time MomentInsights",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Search engine",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services).",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Social media marketing",
        description:
          "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      }
    ],
    items2: [
      {
        title: "Email marketing",
        description:
          "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies.",
        icon: "ti-email rounded mr-3 icon icon-color-3"
      },
      {
        title: "Web design and development",
        description:
          "Your website provides a place for interested users to learn more about your business, your products and services.",
        icon: "ti-vector rounded mr-3 icon icon-color-4"
      }
    ]
  },
  service6: {
    title: "Market your business to own your market",
    description:
      "We provide professional services for grow your business and increases sell digital product"
  },
  serviceAboutUs: {
    title: "We are Agency, We Create Solutions",
    description:
      "We provide professional services for grow your business and increases sell digital product",
    items: [
      {
        title: "Our Mission",
        description: "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        image: "img/mission.jpg"
      },
      {
        title: "Our Vision",
        description: "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements",
        image: "img/vision.jpg"
      },
      {
        title: "Why Us?",
        description: "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies",
        image: "img/grow.jpg"
      },
    ]  
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$29",
        license: "Basic License",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "2 months technical support",
          "2+ profitable keyword"
        ]
      },
      {
        price: "$50",
        license: "Popular License",
        isFeatured: true,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "6 months technical support",
          "30+ profitable keyword"
        ]
      },
      {
        price: "$120",
        license: "Extended License",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "1 year technical support",
          "70+ profitable keyword"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },

      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "AppCo",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Contact with us",
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",

    office: "Head Office",
    address: "121 King St, Melbourne VIC 3000, Australia",
    phone: "+61 2 8376 6284",
    email: "hello@yourdomain.com"
  },
  hero: {
    title: "We are Digital Agency & Marketing",
    description:
      "Rapidiously conceptualize sustainable process improvements without superior infomediaries. Quickly parallel task 24/365 catalysts for change.",
    bgImage: "img/hero-bg-2.jpg",
    animations: [
      {
        className: "img-fluid d-block animation-one",
        src: "img/hero-animation-04.svg",
        width: ""
      },
      {
        className: "img-fluid d-none d-lg-block animation-two",
        src: "img/hero-animation-01.svg",
        width: "120"
      },
      {
        className: "img-fluid d-none d-lg-block animation-three",
        src: "img/hero-animation-02.svg",
        width: "120"
      },
      {
        className: "img-fluid d-none d-lg-block animation-four",
        src: "img/hero-animation-03.svg",
        width: "230"
      }
    ]
  },
  hero2: {
    title: "We are Digital Agency & Marketing",
    description:
      "Quickly morph client-centric results through performance based applications. Proactively facilitate professional human capital for cutting-edge",
    bgImage: "img/hero-bg-1.jpg",
    counters: [
      {
        name: "Happy Client",
        count: 2350
      },
      {
        name: "App Download",
        count: 2150
      },
      {
        name: "Total Rates",
        count: 2350
      },
      {
        name: "Awards win",
        count: 2250
      }
    ]
  },
  hero3: {
    bgImage: "img/curve-bg.jpg",
    images: {
      img1: "img/hero-animation-01.svg",
      img2: "img/hero-animation-02.svg",
      img3: "img/hero-animation-03.svg",
      img4: "img/hero-animation-04.svg"
    },
  },
  hero4: {
    title: "Build anything you want",
    description:
      "We offer Digital Marketing / SEO in Kalawad Road Rajkot, Gujarat. Find here details about our company",
    bgImage: "img/hero-bg-1.jpg"
  },
  hero5: {
    title: "Build anything you want",
    description:
      "Progressively monetize cost effective innovation vis-a-vis cross-unit infrastructures. Uniquely e-enable front-end core competencies vis-a-vis premier",
    bgImage: "img/hero-bg-4.jpg"
  },
  hero7: {
    title: "We are Digital Agency & Marketing",
    description:
      "Professionally simplify integrated partnerships via enterprise-wide leadership skills. Appropriately streamline cross functional infomediaries without resource maximizing internal",
    bgImage: "img/hero-bg-1.jpg"
  },
  heroAboutUs: {
    title: "About Us",
    bgImage: "img/hero-bg-1.jpg"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      "img/team-1.jpg",
      "img/team-2.jpg",
      "img/team-3.jpg",
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
