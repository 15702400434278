import React from "react";
import _data from "../../data";

class Promo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {},
      trustedCompany: []
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    const tempPromo = {};
    tempPromo.title = _data.promo.title;
    tempPromo.description = _data.promo.description;
    tempPromo.items = _data.promo6.items;
    this.setState({
      promo: tempPromo,
      trustedCompany: _data.trustedCompany
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>{this.state.promo.title}</h2>
                  <p className="lead">{this.state.promo.description}</p>
                </div>
              </div>
            </div>
            <div className="row equal">
              {(this.state.promo.items || []).map((item, index) => {
                return (
                  <div className="col-md-4 col-lg-4" key={item.title}>
                    <div className="single-promo single-promo-hover text-center gray-light-bg p-5 h-100">
                      <span className={item.image}></span>
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Promo;
