import React from "react";
import { connect } from "react-redux";
import { getQuote } from "../../actions/index";
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      trustedCompany: [],
      name: "",
      email: "",
      subject: "",
      message: "",
      isAgreed: false,
      disableBtn: false,
      btnText: "Send"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    console.log(this.state);

    // disable the button
    this.setState({ disableBtn: true });

    // // get action
    const quoteAction = getQuote(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(quoteAction);

    // // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Send");

        // get action again to update state
        const quoteAction = getQuote(this.state);

        // Dispatch the contact from data
        this.props.dispatch(quoteAction);

        // clear form data
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
          isAgreed: false
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero,
      trustedCompany: _data.trustedCompanyGray
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section ptb-100 background-img"
          style={{
            background: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "center / cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left text-white mt-lg-0 mt-md-0 mt-5">
                  <h1 className="text-white">
                    {this.state.hero.title}
                  </h1>
                  <p className="lead">
                  {this.state.hero.description}
                  </p>

                  <div className="client-section-wrap mt-5">
                    <p>Trusted by companies like: </p>
                    <ul className="list-inline">
                      {(this.state.trustedCompany || []).map((image, index) => {
                        if(index === 4) {
                          return("");
                        }
                        return (
                          <li className="list-inline-item" key={index}>
                            <img
                              src={image}
                              width="85"
                              alt="client"
                              className="img-fluid"
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
              <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-5">
                  <div className="sign-up-form-header text-center mb-4">
                    <h4 className="mb-0">Get a quote today</h4>
                    <p>Get response within 24 hours</p>
                  </div>
                  <form
                    method="post"
                    className="sign-up-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group input-group">
                      <input
                        value={this.state.name}
                        onChange={e => this.handleFormValueChange("name", e)}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your name"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.email}
                        onChange={e => this.handleFormValueChange("email", e)}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.subject}
                        onChange={e => this.handleFormValueChange("subject", e)}
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Enter subject"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <textarea
                        onChange={e => this.handleFormValueChange("message", e)}
                        value={this.state.message}
                        name="message"
                        id="msg"
                        className="form-control"
                        placeholder="Write your message"
                        cols="30"
                        rows="4"
                        required
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        onClick={() => {
                          this.changeBtnText("Sending...");
                        }}
                        disabled={this.state.disableBtn}
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn solid-btn btn-block"
                        value={this.state.btnText}
                      />
                    </div>
                    <div className="form-check d-flex align-items-center text-center">
                      <input
                        checked={this.state.isAgreed}
                        onChange={e =>
                          this.handleFormValueChange("isAgreed", e)
                        }
                        type="checkbox"
                        name="isAgreed"
                        className="form-check-input mt-0 mr-3"
                        id="ckbAgree"
                      />
                      <label className="form-check-label" htmlFor="ckbAgree">
                        I agree your <a href="/#">terms & conditions</a>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
